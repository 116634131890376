@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .h1-blog {
      @apply text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl;
    }
    .h2-blog {
      @apply pt-5 text-xl font-bold tracking-tight text-gray-900 sm:text-xl;
    }
    .p-blog {
      @apply mx-auto mt-3 text-gray-700 sm:mt-2;
    }
  }
